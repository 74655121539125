import React from 'react';

import './titleBar.css';
export default function TitleBar({title}) {
  return (
    <div className="titleBar">
      <h6 className="title--Bar"> {title} </h6>
    </div>
  );
}
