import { minHeight } from '@mui/system';
import React, { useEffect, useState } from 'react';
import AnchorIcon from '@mui/icons-material/Anchor';

import './card.css';
export default function Card(props) {
  const height = 100 / props.number + '%';

  // console.log("COLOR ST", props.color);
  if (props.size === 'large')
    return (
      <>
        <div
          className="card"
          style={{
            display: 'flex',
            height: height,
            minHeight: height,
            borderBottom: '0.1px solid #808080',
          }}
        >
          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              'padding-left': '10px',
              color: props.departureColor,
            }}
          >
            {props.departure_time ? props.departure_time : <AnchorIcon />}
          </div>

          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              color: props.color,
            }}
          >
            {props.frDestination}
          </div>

          <div
            style={{
              width: '18%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              color: props.color,
            }}
          >
            {props.frLoud}
          </div>
          <div
            style={{
              width: '12%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'center',
              color: props.estimationColor,
            }}
          >
            {props.estimation != 'UNKNOWN' && parseInt(props.estimation) < 2 ? (
              <AnchorIcon />
            ) : (
              props.estimation
            )}
          </div>
          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              color: props.color,
            }}
          >
            {props.arLoud}
          </div>

          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              color: props.color,
            }}
          >
            {props.arDestination}{' '}
          </div>

          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              'padding-right': '10px',
              color: props.departureColor,
            }}
          >
            {props.departure_time ? props.departure_time : <AnchorIcon />}
          </div>
        </div>
      </>
    );
  else if (props.size === 'largeP1')
    return (
      <>
        <div
          className="card"
          style={{
            display: 'flex',
            height: height,
            minHeight: height,
            borderBottom: '0.1px solid #808080',
          }}
        >
          <div
            style={{
              width: '12%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              paddingLeft: '5px',

              color: props.estimationColor,
            }}
          >
            {props.estimation != 'UNKNOWN' && parseInt(props.estimation) < 2 ? (
              <AnchorIcon />
            ) : (
              props.estimation
            )}
          </div>

          <div
            style={{
              width: '16%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              color: props.color,
            }}
          >
            {props.frDestination}
          </div>

          <div
            style={{
              width: '22%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'left',
              color: props.color,
            }}
          >
            {props.frLoud}
          </div>
          <div
            style={{
              width: '12%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'center',
              color: props.departureColor,
            }}
          >
            {props.departure_time ? props.departure_time : <AnchorIcon />}
          </div>
          <div
            style={{
              width: '14%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              color: props.color,
            }}
          >
            {props.arLoud}
          </div>

          <div
            style={{
              width: '12%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              color: props.color,
            }}
          >
            {props.arDestination}
          </div>

          <div
            style={{
              width: '12%',
              justifyContent: 'center',
              display: 'inline-flex',
              'align-items': 'center',
              'justify-content': 'right',
              color: props.estimationColor,
              paddingRight: '5px',
            }}
          >
            {props.estimation != 'UNKNOWN' && parseInt(props.estimation) < 2 ? (
              <AnchorIcon />
            ) : (
              props.estimation
            )}
          </div>
        </div>
      </>
    );
  return (
    <>
      <div
        className="card-s"
        style={{
          display: 'flex',
          height: height,
          minHeight: height,
          borderBottom: '0.1px solid #808080',
        }}
      >
        <div
          style={{
            width: '34%',
            justifyContent: 'center',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color: props.color,
          }}
        >
          {props.arLoud}
        </div>

        <div
          style={{
            width: '24%',
            justifyContent: 'center',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color: props.color,
          }}
        >
          {props.arDestination}{' '}
        </div>

        <div
          style={{
            width: '21%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color: props.departureColor,
          }}
        >
          {props.departure_time ? props.departure_time : <AnchorIcon />}
        </div>
        <div
          style={{
            width: '21%',
            display: 'inline-flex',
            'align-items': 'center',
            'justify-content': 'center',
            color: props.estimationColor,
          }}
        >
          {(props.estimation != 'UNKNOWN' && parseInt(props.estimation)) < 2 ? (
            <AnchorIcon />
          ) : (
            props.estimation
          )}
        </div>
      </div>
    </>
  );
}
