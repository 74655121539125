import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import {
  calendarsActions,
  routesActions,
  tripsActions,
  vehiclesActions,
} from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import CheckItem from "../common/components/CheckItem";
import IconButton from "@mui/material/IconButton";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import TripStopTimeAction from "../common/components/TripStopTimeAction";
import ErrorDialog from "../common/components/ErrorDialog";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
} from '@mui/x-data-grid-generator';
const columns = [
  { field: 'name', headerName: 'Name', width: 180, editable: true },
  { field: 'age', headerName: 'Age', type: 'number', editable: true },
  {
    field: 'dateCreated',
    headerName: 'Date Created',
    type: 'date',
    width: 180,
    editable: true,
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    type: 'dateTime',
    width: 220,
    editable: true,
  },
];

const rows = [
  {
    id: 1,
    name: randomTraderName(),
    age: 25,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 2,
    name: randomTraderName(),
    age: 36,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 3,
    name: randomTraderName(),
    age: 19,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 4,
    name: randomTraderName(),
    age: 28,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
  {
    id: 5,
    name: randomTraderName(),
    age: 23,
    dateCreated: randomCreatedDate(),
    lastLogin: randomUpdatedDate(),
  },
];

export default function TripsDataTable() {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const _trips = useSelector((state) => state.trips.items);
  //console.log("Trips FROM STORE", _trips);
  const _calendarState = useSelector((state) => state.calendars.items);
  const _routeState = useSelector((state) => state.routes.items);
  const _vehicleState = useSelector((state) => state.vehicles.items);
  const _stops = useSelector((state) => state.stops.items);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/trip/all`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        setItems(await response.json());
        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Typography variant="h2" component="h2" align="center">
        TRIPS
      </Typography>
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 5 }}
          checkboxSelection
        />
      </div>
      <CollectionFab editPath="/settings/trip" />
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
