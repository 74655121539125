import { color } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../common/components/ErrorDialog';

import Card from './Card';
import './noTrip.css';

export default function SonotrakTimeTable({
  endpoint,
  action,
  trips,
  sliceNumber,
  size,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log("number", sliceNumber);
  const [loading, setLoading] = useState(false);

  const [sliceIndex, setSliceIndex] = useState(sliceNumber);

  const [reqTimer, setReqTimer] = useState(
    trips.length > 0 ? trips.length * 3000 : 60000
  );

  const [tripSliceIndex, setTripSliceIndex] = useState(0);

  const [basculeTimer, setBasculeTimer] = useState(
    trips.length > sliceIndex
      ? sliceIndex * 3000
      : trips.length > 0
      ? trips.length * 3000
      : 60000
  );
  const [tripsSlice, setTripsSlice] = useState(
    trips.length > 0
      ? trips.slice(0, trips.length > sliceIndex ? sliceIndex : trips.length)
      : trips
  );

  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Error !!');

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  ////console.log("endpoint:", endpoint);

  /////////////////////////////////////////////////////
  //////////// REQUEST TIMER //////////////////////////
  /////////////////////////////////////////////////////

  useEffect(() => {
    let interval = setInterval(async () => {
      //console.log("ENTER TO REQUEST TIMER");

      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_NAME}/${endpoint}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          dispatch(action.setItems(data));
          //console.log("data from use effect ...........", trips);
          return;
        }
      } finally {
        setLoading(false);
      }
      //console.log("REQUEST TIMER");
    }, reqTimer);
    return () => {
      clearInterval(interval);
    };
  }, [reqTimer, dispatch, endpoint, action, trips]);

  useEffect(() => {
    let interval = setInterval(() => {
      //console.log("BASCULE TIMER");

      setTripSliceIndex((prev) =>
        prev >= trips.length ? sliceIndex : prev + sliceIndex
      );
      //console.log("tripSliceIndex ", tripSliceIndex);
    }, basculeTimer);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    //console.log("tripSliceIndex SLICE", tripSliceIndex);

    setTripsSlice(
      trips.length > 0
        ? trips.length - tripSliceIndex <= 0
          ? trips.slice(
              0,
              trips.length < sliceIndex ? trips.length : sliceIndex
            )
          : trips.length - tripSliceIndex >= sliceIndex
          ? trips.slice(tripSliceIndex, tripSliceIndex + sliceIndex)
          : trips.slice(tripSliceIndex, trips.length)
        : trips
    );

    ////console.log("TRIPS SLICE", tripsSlice);
  }, [tripSliceIndex, trips, sliceIndex]);

  function calculArrivalTimeWithEstimation(e, arrival, depart) {
    if (e > 0 && e !== 'UNKNOWN') {
      // console.log("eeeeeeeeeeee",e,arrival)

      const hr = Math.floor(e / 60);
      const min = e % 60;
      //console.log("eeeeeeeeeeee",e,"Hr:",hr)
      // //console.log("Departure",departureTime,"estimation:::",e, "Hr:",hr , "Min:",min)

      //console.log("Final Departue Time: ", depart);
      const estimation_time = _getRtArrivalTime(hr, min);

      //console.log("ESTIMATION", estimation_time);
      return estimation_time;
    } else return arrival;
  }
  function _getRtArrivalTime(hr, min) {
    const time = getCurrentTime();
    //console.log("TIME:",time)
    const splitTime = time.split(':');
    const dhr = parseInt(splitTime[0]) + hr;
    const dmin = parseInt(splitTime[1]) + min;
    //console.log("Hr:",dhr,"Min:",dmin)
    /*console.log(
    "ddddddddddddd",
    d,
    "eeeeeeeeeeee",
    e,
    "hr:",
    hr,
    "min:",
    min,
    "dhr:",
    dhr,
    "Min:",
    dmin
  );*/
    const pfHr = dmin >= 60 ? dhr + Math.floor(dmin / 60) : dhr;
    const pfMin = dmin >= 60 ? dmin % 60 : dmin;
    //console.log("pfhr:", pfHr, "pfMin:", pfMin);
    const fHr = pfHr === 24 ? 0 : pfHr > 24 ? pfHr - 24 : pfHr;
    const arrival =
      fHr.toString().padStart(2, '0') + ':' + pfMin.toString().padStart(2, '0');
    //  console.log("time::", time);
    // console.log("FHR::", fHr);
    // console.log("MIN::", pfMin);

    return arrival;
  }

  function getCurrentTime(separator = ':') {
    const timeZone = 'Africa/Tunis'; // set the timezone

    // create a new date object with the current time in the specified timezone

    let today = new Date();

    let hr = String(
      today.getHours().toLocaleString('en-US', { timeZone })
    ).padStart(2, '0');
    let min = String(
      today.getMinutes().toLocaleString('en-US', { timeZone })
    ).padStart(2, '0');

    // console.log("CURENT TIME")

    return `${hr}${separator}${min}`;
  }
  function calculDepartureTimeWithEstimation(estimation, arrival, departure) {
    let currentTime = getCurrentTime();

    //console.log("CURRENT TIME ", currentTime)

    if (estimation > 5 && estimation !== 'UNKNOWN') {
      const hr = Math.floor(estimation / 60);
      const min = estimation % 60;

      const splitDepart = currentTime.split(':');
      const dhr = parseInt(splitDepart[0]) + hr;
      const dmin = parseInt(splitDepart[1]) + min;

      const pfHr = dmin >= 60 ? dhr + Math.round(dmin / 60) : dhr;
      const pfMin = dmin >= 60 ? dmin % 60 : dmin;
      //  //console.log("pfhr:", pfHr, "pfMin:", pfMin);
      const fHr = pfHr === 24 ? 0 : pfHr > 24 ? pfHr - 24 : pfHr;

      const currentTimeWithEstimation =
        fHr.toString().padStart(2, '0') +
        ':' +
        pfMin.toString().padStart(2, '0');

      // console.log("RT currentTimeWithEstimation", currentTimeWithEstimation)

      return currentTimeWithEstimation;
    }
    return departure;
  }

  const tripsElements =
    trips && trips.length > 0 ? (
      tripsSlice.map((e, index) => {
        return (
          <Card
            key={index}
            number={sliceNumber}
            size={size}
            index={index}
            item={e}
            color={
              e.schedule_relationship === 'UNSCHEDULED'
                ? '#FCAE1E'
                : e.schedule_relationship === 'CANCELED'
                ? '#FF2400'
                : 'whitesmoke'
            }
            departureColor={
              e.schedule_relationship === 'UNSCHEDULED'
                ? '#FCAE1E'
                : e.schedule_relationship === 'CANCELED'
                ? '#FF2400'
                : 'greenyellow'
            }
            departure_time={e.departure_time}
            estimation={calculArrivalTimeWithEstimation(
              e.estimation,
              e.arrival_time,
              e.departure_time
            )}
            estimationColor={
              e.estimation > 0 &&
              e.estimation !== 'UNKNOWN' &&
              e.schedule_relationship === 'SCHEDULED'
                ? '#FCAE1E'
                : e.schedule_relationship === 'UNSCHEDULED'
                ? '#FCAE1E'
                : e.schedule_relationship === 'CANCELED'
                ? '#FF2400'
                : 'greenyellow'
            }
            arLoud={
              e.vehicle_id === '247'
                ? 'تونس'
                : e.vehicle_id === '253'
                ? 'المحيط'
                : e.vehicle_id === '250'
                ? 'سرسينا'
                : e.vehicle_id === '248'
                ? 'حشاد'
                : e.vehicle_id === '251'
                ? 'قرقنة'
                : e.vehicle_id === '249'
                ? 'كيرانيس'
                : 'حبيب عاشور'
            }
            frLoud={
              e.vehicle_id === '247'
                ? ' TUNIS'
                : e.vehicle_id === '253'
                ? ' ELMOUHIT'
                : e.vehicle_id === '250'
                ? 'CERCINA'
                : e.vehicle_id === '248'
                ? 'HACHED'
                : e.vehicle_id === '251'
                ? 'KERKENNAH'
                : e.vehicle_id === '249'
                ? 'KIRANIS'
                : 'HABIB ACHOUR'
            }
            arDestination={e.headsign === 'KERKENNAH' ? 'قرقنة' : 'صفاقس'}
            frDestination={e.headsign === 'KERKENNAH' ? 'KERKENNAH' : 'SFAX'}
          />
        );
      })
    ) : (
      <div
        style={{
          display: 'flex',
          height: '100%',
          'align-items': 'center',
          'justify-content': 'center',
          color: '#808080',
        }}
      >
        <div className="noTrip"> لا يوجد رحلات </div>
      </div>
    );

  //console.log("trips from outside ...........", trips);

  /*
    if (state._kerkenahtrips.isLoading){
        return <h1>Loading ...</h1>
    }
     if (state._kerkenahtrips.isError){
        return <h1>Error ...</h1>
    }  
    */

  return (
    <>
      {tripsElements}
      <ErrorDialog
        style={{ transform: 'none' }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
