import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectAsync } from "../../../reactHelper";
import Header from "../header/Header";
import NavBar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import SonotrakTimeTable from "../SonotrakTimeTable";
import { sfaxTripsActions } from "../../../store";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";

export default function SfaxLed2Page() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate;
  const _sfaxtrips = useSelector((state) => state.sfaxTrips.items);
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/timetable/SONOTRAK/002`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        dispatch(sfaxTripsActions.setItems(data));
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
<>
      
        <NavBar  size="small"/>
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#021430",
          color:"whitesmoke"
        }}
      >
        <SonotrakTimeTable size="small"
          endpoint="timetable/SONOTRAK/002"
          trips={_sfaxtrips}
          action={sfaxTripsActions}
          sliceNumber={3}
        />
      </main>
      <footer style={{ position: "sticky", bottom: 0 }}>
        <Footer size="small"/>
      </footer>
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
