import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkItem from "../common/components/LinkItem";
import moment from "moment";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";

const formatTime = (time) => {
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return `${time.locale("en").format("HH:mm")}`;
};

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const StopTimePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  const [departureValue, setDepartureValue] = useState(
    dayjs("2023-01-01T06:30")
  );
  const [arrivalValue, setArrivalValue] = useState(dayjs("2023-01-01T06:30"));

  const validate = () =>
    item &&
    item.name &&
    item.trip_id &&
    item.stop_id &&
    item.arrival_time &&
    item.departure_time &&
    item.stop_sequence 

  function callBack(attribut, value) {
    //console.log("CallBack Function ** ATTRIBUT", attribut, "VALUE", value);
    attribut === "service_id"
      ? setItem({ ...item, service_id: value })
      : attribut === "route_id"
      ? setItem({ ...item, route_id: value })
      : attribut === "trip_id"
      ? setItem({ ...item, trip_id: value })
      : setItem({ ...item, stop_id: value });
  }

  function handleDepartureTime(e) {
    const time = formatTime(e);
   /* console.log(
      "Departure Time:",
      "HR:",
      e.hour(),
      "Min:",
      e.minute(),
      "time:",
      time
    );*/

    setItem({ ...item, departure_time: time });
  }

  function handleArrivalTime(e) {
    const time = formatTime(e);
 /*console.log(
      "Arrival Time:",
      "HR:",
      e.hour(),
      "Min:",
      e.minute(),
      "time:",
      time
    );*/

    setItem({ ...item, arrival_time: time });
  }

  return (
    <EditItemView
      endpoint="stoptime"
      item={item}
      setItem={setItem}
      validate={validate}
      //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                // label={t('sharedName')}
                label="Name"
              />
              <LinkItem
                endpointAll="trip"
                linkedItem={item}
                baseId={item._id}
                keyBase="trip_id"
                keyLink="trip_id"
                label="Trip"
                handleCallBack={callBack}
              />
              <LinkItem
                endpointAll="stop"
                linkedItem={item}
                baseId={item._id}
                keyBase="stop_id"
                keyLink="stop_id"
                label="Stop"
                handleCallBack={callBack}
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  label="Arrival Time"
                  value={
                    item.arrival_time
                      ? dayjs(`2023-01-01T${item.arrival_time}`)
                      : departureValue
                  }
                  onChange={(newValue) => handleArrivalTime(newValue)}
                  format="HH:mm"
                />

                <TimeField
                  label="Daparture Time"
                  value={
                    item.departure_time
                      ? dayjs(`2023-01-01T${item.departure_time}`)
                      : departureValue
                  }
                  onChange={(newValue) => handleDepartureTime(newValue)}
                  format="HH:mm"
                />
              </LocalizationProvider>

              <TextField
                value={item.stop_sequence || ""}
                onChange={(event) =>
                  setItem({ ...item, stop_sequence: event.target.value })
                }
                label="Stop Sequence"
              />

              <FormControl>
                <InputLabel>{"Stop HeadSign"}</InputLabel>
                <Select
                  label={"Stop HeadSign"}
                  value={
                    item.stop_headsign === "KERKENAH"
                      ? "KERKENAH"
                      : item.stop_headsign === "SFAX"
                      ? "SFAX"
                      : ""
                  }
                  onChange={(e) =>
                    setItem({ ...item, stop_headsign: e.target.value })
                  }
                >
                  <MenuItem value="KERKENAH">KERKENAH</MenuItem>
                  <MenuItem value="SFAX">SFAX</MenuItem>
                </Select>
              </FormControl>
              
              <TextField
                value={item.pickup_type || ""}
                onChange={(event) =>
                  setItem({ ...item, pickup_type: event.target.value })
                }
                label="Pick Up Type"
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default StopTimePage;
