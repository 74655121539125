import { Routes, Route } from 'react-router-dom';

import App from './App';
import LoginPage from './login/LoginPage';
import AgenciesPage from './pages/AgenciesPage';
import AgencyPage from './pages/AgencyPage';
import StopPage from './pages/StopPage';
import StopsPage from './pages/StopsPage';
import TripsPage from './pages/TripsPage'
import TripPage from './pages/TripPage'
import StopTimesPage from './pages/StopTimesPage'
import StopTimePage from './pages/StopTimePage'
import RoutesPage from './pages/RoutesPage'
import RoutePage from './pages/RoutePage'
import CalendarsPage from './pages/CalendarsPage'
import CalendarPage from './pages/CalendarPage'
import VehiclesPage from './pages/VehiclesPage'
import VehiclePage from './pages/VehiclePage'
import UsersPage from './pages/UsersPage'
import UserPage from './pages/UserPage'
import KerkenahLed1Page from './pages/sonotrak/kerkenah/KerkenahLed1Page'
import SfaxLed1Page from './pages/sonotrak/sfax/SfaxLed1Page';
import KerkenahLed2Page from './pages/sonotrak/kerkenah/KerkenahLed2Page';
import SfaxLed2Page from './pages/sonotrak/sfax/SfaxLed2Page';
import TimeTablePage from './pages/sonotrak/TimeTablePage';
import TripStopTimesPage from './pages/tripStopTimes';
import TripStopTimePage from './pages/TripStopTime';
import PageNotFound from './pages/PageNotFound';
import TodayTimeTablePage from './pages/sonotrak/TodayTimeTablePage';
import TimeTablePageP1 from './pages/sonotrak/TimeTablePageP1';
import SfaxLed1PageP1 from './pages/sonotrak/sfax/SfaxLED1PageP1';
import KerkenahLed1PageP1 from './pages/sonotrak/kerkenah/KerkenahLed1PageP1';
import SfaxOutdoorPage from './pages/sonotrak/SfaxOutdoor';
import KerkenahOutdoorPage from './pages/sonotrak/KerknahOutdoor';
import TodayTimeTableKerkenahPage from './pages/sonotrak/TodayTimeTableKerkenahPage';
import TodayTimeTableSfaxPage from './pages/sonotrak/TodayTimeTableSfax';
import TripsDataTable from './pages/TripsDataTable';

const Navigation = () => {
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/login" element={<LoginPage />} />

      <Route path="/sonotrak" >
      <Route path="sfaxL1" element={<SfaxLed1Page />} />
      <Route path="sfaxL1P1" element={<SfaxLed1PageP1 />} />

      <Route path="sfaxL2" element={<SfaxLed2Page />} />

        <Route path="kerkenahL1" element={<KerkenahLed1Page />} />
        <Route path="kerkenahL1P1" element={<KerkenahLed1PageP1 />} />

        <Route path="kerkenahL2" element={<KerkenahLed2Page />} />
        <Route path="timetable" element={<TimeTablePage />} />
        <Route path="timetableP1" element={<TimeTablePageP1 />} />
        <Route path="sfaxOutdoor" element={<SfaxOutdoorPage />} />
        <Route path="kerkenahOutdoor" element={<KerkenahOutdoorPage />} />



        <Route path="todayTimeTable" element={<TodayTimeTablePage />} />
        <Route path="todayTimeTableSfax" element={<TodayTimeTableSfaxPage />} />
        <Route path="todayTimeTableKerkenah" element={<TodayTimeTableKerkenahPage />} />




        </Route>

      <Route path="/" element={<App />}>
        <Route index element={<TripsPage />} />
      
       
        

        <Route path="settings">
          
        <Route path="tripstoptimes" element={<TripStopTimesPage />} />
          <Route path="tripstoptime/:id" element={<TripStopTimePage />} />
          <Route path="tripstoptime" element={<TripStopTimePage />} />

          <Route path="stops" element={<StopsPage />} />
          <Route path="stop/:id" element={<StopPage />} />
          <Route path="stop" element={<StopPage />} />
          
          <Route path="trips" element={<TripsPage />} />
          <Route path="tripsData" element={<TripsDataTable />} />

          <Route path="trip/:id" element={<TripPage />} />
          <Route path="trip" element={<TripPage />} />

          <Route path="stoptimes" element={<StopTimesPage />} />
          <Route path="stoptime/:id" element={<StopTimePage />} />
          <Route path="stoptime" element={<StopTimePage />} />

          <Route path="agencies" element={<AgenciesPage />} />
          <Route path="agency/:id" element={<AgencyPage />} />
          <Route path="agency" element={<AgencyPage />} />

          <Route path="calendars" element={<CalendarsPage />} />
          <Route path="calendar/:id" element={<CalendarPage />} />
          <Route path="calendar" element={<CalendarPage />} />

          <Route path="routes" element={<RoutesPage />} />
          <Route path="route/:id" element={<RoutePage />} />
          <Route path="route" element={<RoutePage />} />

          <Route path="vehicles" element={<VehiclesPage />} />
          <Route path="vehicle/:id" element={<VehiclePage />} />
          <Route path="vehicle" element={<VehiclePage />} />

          <Route path="users" element={<UsersPage />} />
          <Route path="user/:id" element={<UserPage />} />
          <Route path="user" element={<UserPage />} />

        </Route>

      </Route>
    </Routes>
  );
};

export default Navigation;
