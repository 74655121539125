import { height } from "@mui/system";
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";

import './footer.css'
import Time from "./Time";
export default function Message({ message, tempC ,direction ,size}) {
 


 

  return (<>
      <div className="footer" style={{ display: "flex", alignItems: "center" ,justifyContent:"center" }}>
      <Marquee
      speed={size==="large"?10:6}
        style={{
          backgroundColor: "#03a9f4",
          height: "10%",
          color: "whitesmoke",
          
        }}
        gradient={false}
        direction={direction}
      >
        {message}
      </Marquee>
      <Time size={size} tempC={tempC}/>
    </div>
   
  </>

  );
}
