import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import {
  calendarsActions,
  routesActions,
  tripsActions,
  vehiclesActions,
} from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import CheckItem from "../common/components/CheckItem";
import IconButton from "@mui/material/IconButton";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import TripStopTimeAction from "../common/components/TripStopTimeAction";
import ErrorDialog from "../common/components/ErrorDialog";

const TripsPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [checkedValue, setCheckedValue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const _trips = useSelector((state) => state.trips.items);
  //console.log("Trips FROM STORE", _trips);
  const _calendarState = useSelector((state) => state.calendars.items);
  const _routeState = useSelector((state) => state.routes.items);
  const _vehicleState = useSelector((state) => state.vehicles.items);
  const _stops = useSelector((state) => state.stops.items);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/trip/all`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }    } finally {
      setLoading(false);
    }
  }, []);

  //console.log("itemms ", items);

  function getItemById(label, id, action, endpoint, state) {
    const item = state.filter((element) => element._id === id);
    if (item.length > 0) {
      return checkedValue;
    }

    return (
      <CheckItem label={label} id={id} action={action} endpoint={endpoint} />
    );
  }
 
  return (
    <>
        <Typography
        variant="h2"
        component="h2"
        align="center"
        
      >
        TRIPS
      </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#D3D3D3" }}>
            <TableCell>TRIP ID</TableCell>

            <TableCell>HEADSIGN</TableCell>
            <TableCell>SHORT NAME</TableCell>
            <TableCell>SERVICE</TableCell>
            <TableCell>ROUTE</TableCell>
            <TableCell>VEHICLE</TableCell>
            <TableCell>SCHEDULE RELATIONSHIP</TableCell>
            <TableCell>STOPTIMES</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!loading && _trips.length > 0 ? (
            _trips.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.trip_id}</TableCell>
                <TableCell>{item.trip_headsign}</TableCell>
                <TableCell>{item.trip_short_name}</TableCell>
                {item.service_id===null?
                <TableCell>null</TableCell>:<TableCell>  {item.service_id.name}</TableCell>}
                <TableCell>{item.route_id.route_long_name}</TableCell>
                <TableCell>{item.vehicle_id.name}</TableCell>
             
                <TableCell>{item.schedule_relationship}</TableCell>
                <TableCell>
                  {" "}
                <TripStopTimeAction 
                 itemId={item._id}
                 editPath="/settings/tripstoptimes"
                 item={item}
                 stops={_stops}
                />
                </TableCell>

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/trip"
                    endpoint="trip"
                    action={tripsActions}
                    removable={true}

                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/trip" />
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default TripsPage;
