import React, { useState } from "react";
import Marquee from "react-fast-marquee";

import Message from "./Message";
import Time from "./Time";
import { useEffectAsync } from "../../../reactHelper";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../common/components/ErrorDialog";

export default function Footer({ size }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(
    "   الشـــركة الجديدة للنقـل بقرقنة تتمنــى لحرفــــائــها الكـرام سفرة ممتـعة    "
  );
  const [direction, setDirection] = useState("right");

  const navigate = useNavigate();
  const [temperature, setTemperature] = useState();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/timetable`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            setMsg("");
            if (data && data.length > 0) {
             // console.log("MSG MSG");

              data.map((item) =>
                setMsg(
                  (prev) =>
                    prev +
                    "Direction " +
                    item.headsign +
                    " " +
                    item.departure_time +
                    "  ***  "
                )
              );
              setDirection("left");
            } else {
              setMsg(
                "الشـــركة الجديدة للنقـل بقرقنة تتمنــى لحرفــــائــها الكـرام سفرة ممتـعة"
              );
              setDirection("right");
            }
          });
          return
      }  if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  useEffectAsync(async () => {
    // Update the current time every second
    try {
      const response = await fetch(
        "https://api.weatherapi.com/v1/current.json?key=b1802813d5814afe819123308221504&q=34.721 11.016&aqi=no",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("TEMPERATURE FOOTER:", data.current.temp_c);
            setTemperature(data.current.temp_c);
          });
          return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
    }

    // Cleanup the interval on unmount
  }, []);

  return (
    <>
      <Message
        message={msg}
        tempC={temperature}
        direction={direction}
        size={size}
      />

      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
