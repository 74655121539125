import React from 'react';
import './navbar.css';
export default function NavBar({ size }) {
  if (size === 'large')
    return (
      <div
        className="navbar"
        style={{
          display: 'flex',
          backgroundColor: '#03a9f4',
          color: 'whiteSmoke',
          height: '16%',
        }}
      >
        <div
          style={{
            width: '14%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft: '10px',
          }}
        >
          <div style={{}}> DEPART</div>
        </div>
        <div
          style={{
            width: '14%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <div style={{}}> DEST</div>
        </div>
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft: '12px',
          }}
        >
          <div style={{}}> SHIP</div>
        </div>
        <div
          style={{
            width: '22%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div style={{}}> ARRIVAL | الوصول</div>
        </div>
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight: '5px',
          }}
        >
          <div style={{}}> السفينة</div>
        </div>
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div style={{}}> الوجهة</div>
        </div>
        <div
          style={{
            width: '14%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight: '10px',
          }}
        >
          <div style={{}}> الانطلاق</div>
        </div>
      </div>
    );
    if (size === 'largeP1')
    return (
      <div
        className="navbar"
        style={{
          display: 'flex',
          backgroundColor: '#03a9f4',
          color: 'whiteSmoke',
          height: '16%',
        }}
      >
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft: '2px',
          }}
        >
          <div style={{}}> ARRIVAL </div>
        </div>
        <div
          style={{
            width: '16%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <div style={{}}> DEST</div>
        </div>
        <div
          style={{
            width: '18%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          <div style={{}}> SHIP</div>
        </div>
        <div
          style={{
            width: '20%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',

          }}
        >
          <div style={{}}>DEPART الانطلاق</div>
        </div>
        <div
          style={{
            width: '10%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div style={{}}> السفينة</div>
        </div>
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div style={{}}> الوجهة</div>
        </div>
        <div
          style={{
            width: '12%',
            justifyContent: 'center',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight:"2px"
          }}
        >
          <div style={{}}> الوصول </div>
        </div>
      </div>
    );
  return (
    <div
      className="navbar"
      style={{
        display: 'flex',
        backgroundColor: '#03a9f4',
        color: 'whiteSmoke',
        height: '25%',
      }}
    >
      <div
        style={{
          width: '34%',
          justifyContent: 'center',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{}}> السفينة</div>
      </div>
      <div
        style={{
          width: '24%',
          justifyContent: 'center',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{}}> الوجهة</div>
      </div>
      <div
        style={{
          width: '21%',
          justifyContent: 'center',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',

        }}
      >
        <div style={{}}> الانطلاق</div>
      </div>
      <div
        style={{
          width: '21%',
          justifyContent: 'center',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{}}> الوصول</div>
      </div>
    </div>
  );
}
