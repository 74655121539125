import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../common/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import LinkItem from '../common/components/LinkItem';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const RoutePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.agency_id &&
    item.route_id &&
    item.route_short_name &&
    item.route_long_name ;

    function callBack(attribut, value) {
      //console.log("CallBack Function ** ATTRIBUT", attribut, "VALUE", value);
      attribut === "service_id"
        ? setItem({ ...item, service_id: value })
        : attribut === "route_id"
        ? setItem({ ...item, route_id: value })
        : attribut === "trip_id"
        ? setItem({ ...item, trip_id: value }):attribut === "agency_id"
        ? setItem({ ...item, agency_id: value })
        : setItem({ ...item, stop_id: value });
    }
  return (
    <EditItemView
      endpoint="route"
      item={item}
      setItem={setItem}
      validate={validate}
    //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t('sharedRequired')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
            <LinkItem
                endpointAll="agency"
                linkedItem={item}
                baseId={item._id}
                keyBase="agency_id"
                keyLink="agency_id"
                label="Agency ID"
                handleCallBack={callBack}
              />
              <TextField
                value={item.route_id || ''}
                onChange={(event) =>
                  setItem({ ...item, route_id: event.target.value })
                }
               // label={t('sharedName')}
               label= "Route ID"
              />
               <TextField
                value={item.route_short_name || ''}
                onChange={(event) =>
                  setItem({ ...item, route_short_name: event.target.value })
                }
               // label={t('sharedName')}
               label= "Route Short Name"
              />
               <TextField
                value={item.route_long_name || ''}
                onChange={(event) =>
                  setItem({ ...item, route_long_name: event.target.value })
                }
               // label={t('sharedName')}
               label= "Route Long Name"
              />


          
           
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default RoutePage;
