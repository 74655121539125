import logo from "./logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

import { agenciesActions, calendarsActions, routesActions, stopsActions, stopTimesActions, tripsActions, usersActions, vehiclesActions } from "./store";
import theme from "./theme";
import Drawer from "./drawer/drawer";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "./common/util/localstorage";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Token, setToken] = useState(null);

  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const url = `${process.env.REACT_APP_URL_NAME}`;
  const _isAdministrator = useSelector((state) => state.session.user.administrator);

  const fetchAgencyData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/agency`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        dispatch(agenciesActions.update(await response.json()));
      }
      if (response.status === 401) {
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        // throw Error(await response.text());
      }
    } catch (error) {
      //console.log("there is an error ");
    }
  };
  const fetchStopsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/stop`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        dispatch(stopsActions.setItems(await response.json()));
      }
      if (response.status === 401) {
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        // throw Error(await response.text());
      }
    } catch (error) {
      //console.log("there is an error ");
    }
  };
  const fetchTripsData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/trip`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data trips", data);
            dispatch(tripsActions.setItems(data));
          });
        return
      }
         } finally {
    }
  };
  const fetchCalendarsData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/calendar`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data calendars", data);
            dispatch(calendarsActions.setItems(data));
          });
        return
      }
 
    } finally {
    }
  };
  const fetchStopTimesData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/stoptime`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data stoptimes:: ", data);
            dispatch(stopTimesActions.setItems(data));
          });
        return
      }
     
    } finally {
    }
  };
  const fetchVehiculesData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/vehicle`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data calendars", data);
            dispatch(vehiclesActions.setItems(data));
          });
        return
      }
   
    } finally {
    }
  };
  const fetchUsersData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/user`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data users", data);
            dispatch(usersActions.setItems(data));
            //console.log("USERS DISPATCHED");
          });
        return
      }
      
    } finally {
    }
  };
 
  const fetchRoutesData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/route`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data route", data);
            dispatch(routesActions.setItems(data));
          });
        return
      }
 
    } finally {
    }
  };
  useEffect(() => {
    if (getWithExpiry("TOKEN") == null) {
      navigate("/login");
    } else {
      fetchAgencyData();
      fetchStopsData();
      fetchTripsData();
      fetchStopTimesData();
      fetchCalendarsData();
      fetchRoutesData();
      fetchVehiculesData();

      if(_isAdministrator){
        fetchUsersData();
      }
    }
  }, []);

  return <Drawer />;
}
export default App;
