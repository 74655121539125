import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import { calendarsActions } from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../common/components/ErrorDialog";

const CalendarsPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const _calendars = useSelector((state) => state.calendars.items);
  //console.log("Calendar FROM STORE", _calendars);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/calendar`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data calendars", data);
            dispatch(calendarsActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  //console.log("itemms ", items);
  return (
    <>

        <Typography
        variant="h2"
        component="h2"
        align="center"
      >
        CALENDARS
      </Typography>
      
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>

        <TableHead>
          <TableRow style={{backgroundColor: "#D3D3D3"}}>
            <TableCell>NAME</TableCell>
            <TableCell>START DATE</TableCell>
            <TableCell>END DATE</TableCell>
            <TableCell>MONDAY</TableCell>
            <TableCell>TUESDAY</TableCell>
            <TableCell>WEDNESDAY</TableCell>
            <TableCell>THURSDAY</TableCell>
            <TableCell>FRIDAY</TableCell>
            <TableCell>SATURDAY</TableCell>
            <TableCell>SUNDAY</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && _calendars.length > 0 ? (
            _calendars.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.name}</TableCell>

                <TableCell>{[item.start_date.slice(0, 4), "-", item.start_date.slice(4,6),"-", item.start_date.slice(6,8)].join('')}</TableCell>
                <TableCell>{[item.end_date.slice(0, 4), "-", item.end_date.slice(4,6),"-", item.end_date.slice(6,8)].join('')}</TableCell>
                <TableCell>{item.Monday}</TableCell>
                <TableCell>{item.Tuesday}</TableCell>
                <TableCell>{item.Wednesday}</TableCell>
                <TableCell>{item.Thursday}</TableCell>
                <TableCell>{item.Friday}</TableCell>
                <TableCell>{item.Saturday}</TableCell>
                <TableCell>{item.Sunday}</TableCell>

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/calendar"
                    endpoint="calendar"
                    action={calendarsActions}
                    removable={true}

                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/calendar" />
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default CalendarsPage;
