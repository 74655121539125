import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import NavBar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { timetableActions } from "../../store";
import SonotrakTimeTable from "./SonotrakTimeTable";
import { useEffectAsync } from "../../reactHelper";
import ErrorDialog from "../../common/components/ErrorDialog";
export default function TimeTablePage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _timeTabletrips = useSelector((state) => state.timetable.items);
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  useEffectAsync(async () => {
    console.log("ENTER TO FETCH DATA");
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/timetable`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
       // console.log("RES OK");
        const data = await response.json();
        dispatch(timetableActions.setItems(data));
        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };

  return (
    <>
      <Header />
      <NavBar size="large" />
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#021430",
          color: "whitesmoke",
        }}
      >
        <SonotrakTimeTable
          size="large"
          endpoint="timetable"
          trips={_timeTabletrips}
          action={timetableActions}
          sliceNumber={5}
        />
      </main>
      <footer style={{ position: "sticky", bottom: 0 }}>
        <Footer size="large" />
      </footer>

      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
