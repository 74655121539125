import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../common/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const VehiclePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.id &&
    item.name &&
    item.category &&
    item.positionId &&
    item.uniqueId&&
    item.course &&
    item.fixTime &&
    item.latitude &&
    item.longitude &&
    item.speed ;

  return (
    <EditItemView
      endpoint="vehicle"
      item={item}
      setItem={setItem}
      validate={validate}
    //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t('sharedRequired')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

            <TextField
                value={item.id || ''}
                onChange={(event) =>
                  setItem({ ...item, id: event.target.value })
                }
               // label={t('sharedName')}
               label= "Id"
              />
               <TextField
                value={item.name || ''}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
               // label={t('sharedName')}
               label= "Name"
              />
               <TextField
                value={item.uniqueId || ''}
                onChange={(event) =>
                  setItem({ ...item, uniqueId: event.target.value })
                }
               // label={t('sharedName')}
               label= "Unique Id"
              />


              <TextField
                value={item.category || ''}
                onChange={(event) =>
                  setItem({ ...item, category: event.target.value })
                }
               // label={t('sharedName')}
               label= "Category"
              />
              <TextField
                value={item.positionId || ''}
                onChange={(event) =>
                  setItem({ ...item, positionId: event.target.value })
                }
                label= "Position Id"
                />
              <TextField
                value={item.course || ''}
                onChange={(event) =>
                  setItem({ ...item, course: event.target.value })
                }
                label= "Course"
                />
              <TextField
                value={item.fixTime || ''}
                onChange={(event) =>
                  setItem({ ...item, fixTime: event.target.value })
                }
                label= "Fix Time"
                />
              <TextField
                value={item.latitude || ''}
                onChange={(event) =>
                  setItem({ ...item, latitude: event.target.value })
                }
                label= "Latitude"

              />
                 <TextField
                value={item.longitude || ''}
                onChange={(event) =>
                  setItem({ ...item, longitude: event.target.value })
                }
                label= "Longitude"

              />
                 <TextField
                value={item.speed || ''}
                onChange={(event) =>
                  setItem({ ...item, speed: event.target.value })
                }
                label= "Speed"

              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default VehiclePage;
