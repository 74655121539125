import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkItem from "../common/components/LinkItem";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TripPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.schedule_relationship &&
    item.route_id &&
    item.trip_id &&
    item.service_id &&
    item.trip_headsign &&
    item.trip_short_name &&
    item.vehicle_id;

  function callBack(attribut, value) {
    //console.log("CallBack Function ** ATTRIBUT", attribut, "VALUE", value);
    attribut === "service_id"
      ? setItem({ ...item, service_id: value })
      : attribut === "route_id"
      ? setItem({ ...item, route_id: value })
      : setItem({ ...item, vehicle_id: value });
  }
  return (
    <EditItemView
      endpoint="trip"
      item={item}
      setItem={setItem}
      validate={validate}
      //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.trip_id || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_id: event.target.value })
                }
                // label={t('sharedName')}
                label="trip id"
              />
              <FormControl>
                <InputLabel>{"Trip HeadSign"}</InputLabel>
                <Select
                  label={"Trip HeadSign"}
                  value={
                    item.trip_headsign === "KERKENNAH"
                      ? "KERKENNAH"
                      : item.trip_headsign === "SFAX"
                      ? "SFAX"
                      : ""
                  }
                  onChange={(e) =>
                    setItem({ ...item, trip_headsign: e.target.value })
                  }
                >
                  <MenuItem value="KERKENNAH">KERKENAH</MenuItem>
                  <MenuItem value="SFAX">SFAX</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel>{"schedule relationship"}</InputLabel>
                <Select
                  label={"schedule relationship"}
                  value={
                    item.schedule_relationship === "SCHEDULED"
                      ? "SCHEDULED"
                      : item.schedule_relationship === "CANCELED"
                      ? "CANCELED"
                      : item.schedule_relationship === "UNSCHEDULED"
                      ? "UNSCHEDULED"
                      : ""
                  }
                  onChange={(e) =>
                    setItem({ ...item, schedule_relationship: e.target.value })
                  }
                >
                  <MenuItem value="SCHEDULED">SCHEDULED</MenuItem>
                  <MenuItem value="UNSCHEDULED">UNSCHEDULED</MenuItem>
                  <MenuItem value="CANCELED">CANCELED</MenuItem>
                </Select>
              </FormControl>

              <TextField
                value={item.trip_short_name || ""}
                onChange={(event) =>
                  setItem({ ...item, trip_short_name: event.target.value })
                }
                // label={t('sharedName')}
                label="trip short name"
              />

              <LinkItem
                endpointAll="calendar"
                linkedItem={item}
                baseId={item._id}
                keyBase="service_id"
                keyLink="service_id"
                label="Service"
                handleCallBack={callBack}
              />
              <LinkItem
                endpointAll="route"
                linkedItem={item}
                baseId={item._id}
                keyBase="route_id"
                keyLink="route_id"
                label="Route"
                handleCallBack={callBack}
              />

              <LinkItem
                endpointAll="vehicle"
                linkedItem={item}
                baseId={item._id}
                keyBase="vehicle_id"
                keyLink="service_id"
                label="Vehicle"
                handleCallBack={callBack}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default TripPage;
