import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'sfaxTrips',
  initialState: {
    items: [],
  },
  reducers: {

    setItems(state, action) {
      state.items = action.payload;
    },

  },
});

export { actions as kerkenahTripsActions };
export { reducer as kerkenahTripsReducer };
