import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../common/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const StopPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.stop_code &&
    item.stop_id &&
    item.stop_name &&
    item.stop_desc &&
    item.stop_lon &&
    item.stop_lat;

  return (
    <EditItemView
      endpoint="stop"
      item={item}
      setItem={setItem}
      validate={validate}
    //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t('sharedRequired')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.stop_code || ''}
                onChange={(event) =>
                  setItem({ ...item, stop_code: event.target.value })
                }
               // label={t('sharedName')}
               label= "Stop code"
              />
                  <TextField
                value={item.stop_id || ''}
                onChange={(event) =>
                  setItem({ ...item, stop_id: event.target.value })
                }
               // label={t('sharedName')}
               label= "Stop ID"
              />
            
                <FormControl>
                <InputLabel>{"Stop Name"}</InputLabel>
                <Select
                  label={"Stop Name"}
                  value={
                    item.stop_name === "KERKENNAH"
                      ? "KERKENNAH"
                      : item.stop_name === "SFAX"
                      ? "SFAX"
                      : ""
                  }
                  onChange={(e) =>
                    setItem({ ...item, stop_name: e.target.value })
                  }
                >
                  <MenuItem value="KERKENNAH">KERKENNAH</MenuItem>
                  <MenuItem value="SFAX">SFAX</MenuItem>
                </Select>
              </FormControl>
              <TextField
                value={item.stop_desc || ''}
                onChange={(event) =>
                  setItem({ ...item, stop_desc: event.target.value })
                }
                label= "Stop description"
                />
              <TextField
                value={item.stop_lon || ''}
                onChange={(event) =>
                  setItem({ ...item, stop_lon: event.target.value })
                }
                label= "Stop longitude"
                />
              <TextField
                value={item.stop_lat || ''}
                onChange={(event) =>
                  setItem({ ...item, stop_lat: event.target.value })
                }
                label= "Stop Latitude"

              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default StopPage;
