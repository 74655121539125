import React, { useEffect, useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from '@mui/material';
import { useEffectAsync } from '../reactHelper';
import { getWithExpiry } from '../common/util/localstorage';
import SearchHeader, {
  filterByKeyword,
} from '../common/components/SearchHeader';

import CollectionActions from '../common/components/CollectionActions';
import { stopTimesActions } from '../store';
import TableShimmer from '../common/components/TableShimmer';
import { useDispatch, useSelector } from 'react-redux';
import CollectionFab from '../settings/CollectionFab';
import { useNavigate } from 'react-router-dom';
import CheckItem from '../common/components/CheckItem';
import ErrorDialog from '../common/components/ErrorDialog';

const StopTimesPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const _stoptimes = useSelector((state) => state.stoptimes.items);
  const _tripsState = useSelector((state) => state.trips.items);
  const _stopsState = useSelector((state) => state.stops.items);

  //console.log("STOPTIMESS FROM STORE", _stoptimes);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Error !!');

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/stoptime`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getWithExpiry('TOKEN')}`,
          },
        }
      );
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data stoptimes:: ", data);
            dispatch(stopTimesActions.setItems(data));
          });
        setItems(await response.json());
        return;
      }
      if (response.status === 401) {
        setErrorMsg('UNAUTHORIZED');
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate('/login');
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  //console.log("itemms ", items);
  return (
    <>
      <Typography variant="h2" component="h2" align="center">
        STOP TIMES
      </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: '#D3D3D3' }}>

            <TableCell>TRIP</TableCell>
            <TableCell>TRIP SHORT NAME</TableCell>

            <TableCell>STOP NAME</TableCell>
            <TableCell>ARRIVAL TIME</TableCell>
            <TableCell>DEPARTURE TIME</TableCell>
            <TableCell>STOP SEQUENCE</TableCell>

            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && _stoptimes.length > 0 ? (
            _stoptimes.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>

                <TableCell>
                  {' '}
                  {
                    <CheckItem
                      id={item.trip_id}
                      state={_tripsState}
                      label="trip_id"
                      endpoint={`trip/${item.trip_id}`}
                    />
                  }
                </TableCell>
                <TableCell>
                  {' '}
                  {
                    <CheckItem
                      id={item.trip_id}
                      state={_tripsState}
                      label="trip"
                      endpoint={`trip/${item.trip_id}`}
                    />
                  }
                </TableCell>
                <TableCell>
                  <CheckItem
                    label="stop"
                    id={item.stop_id}
                    state={_stopsState}
                    endpoint={`stop/${item.stop_id}`}
                  />
                </TableCell>
                <TableCell>{item.arrival_time}</TableCell>
                <TableCell>{item.departure_time}</TableCell>
                <TableCell>{item.stop_sequence}</TableCell>

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/stoptime"
                    endpoint="stoptime"
                    action={stopTimesActions}
                    removable={true}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/stoptime" />
      <ErrorDialog
        style={{ transform: 'none' }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default StopTimesPage;
