import React from 'react';
import sonotrakLogo from '../../../resources/images/sonotrak.png';

import './header.css';
export default function Header() {
  return (
    <header className="header">
      <img alt="" className="logo" src={sonotrakLogo} />
      <h1 className="header--title">الشركة الجديدة للنقل بقرقنة</h1>
      <img alt="" className="logo" src={sonotrakLogo} />
    </header>
  );
}
