import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { useEffectAsync } from "../reactHelper";
import { getWithExpiry } from "../common/util/localstorage";
import SearchHeader, {
  filterByKeyword,
} from "../common/components/SearchHeader";

import CollectionActions from "../common/components/CollectionActions";
import { routesActions } from "../store";
import TableShimmer from "../common/components/TableShimmer";
import { useDispatch, useSelector } from "react-redux";
import CollectionFab from "../settings/CollectionFab";
import { useNavigate } from "react-router-dom";
import CheckItem from "../common/components/CheckItem";
import ErrorDialog from "../common/components/ErrorDialog";

const RoutesPage = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const _routes = useSelector((state) => state.routes.items);
  const _agencyState = useSelector((state) => state.agencies.items);

  //console.log("Routes FROM STORE", _routes);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL_NAME}/route`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getWithExpiry("TOKEN")}`,
        },
      });
      if (response.ok) {
        response
          .clone()
          .json()
          .then((data) => {
            //console.log("data route", data);
            dispatch(routesActions.setItems(data));
          });
        setItems(await response.json());
        return
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  //console.log("itemms ", items);
  return (
    <>
          <Typography
        variant="h2"
        component="h2"
        align="center"
      >
        ROUTES
      </Typography>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />

      <Table>
        <TableHead>
          <TableRow style={{backgroundColor: "#D3D3D3"}}>
            <TableCell>AGENCY</TableCell>
            <TableCell>ROUTE ID</TableCell>
            <TableCell>SHORT NAME</TableCell>
            <TableCell>LONG NAME</TableCell>
     
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && _routes.length > 0 ? (
            _routes.filter(filterByKeyword(searchKeyword)).map((item) => (
              <TableRow key={item._id}>
                <TableCell><CheckItem id={item.agency_id} state={_agencyState} label="agency" endpoint={`agency/${item.agency_id}`}/></TableCell>
                <TableCell>{item.route_id}</TableCell>

                <TableCell>{item.route_short_name}</TableCell>
                <TableCell>{item.route_long_name}</TableCell>
      

                <TableCell padding="none">
                  <CollectionActions
                    itemId={item._id}
                    editPath="/settings/route"
                    endpoint="route"
                    action={routesActions}
                    removable={true}

                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableShimmer columns={3} endAction />
          )}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/route" />
      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
};

export default RoutesPage;
