import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import NavBar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import { timetableActions } from "../../store";
import SonotrakTimeTable from "./SonotrakTimeTable";
import { useEffectAsync } from "../../reactHelper";
import ErrorDialog from "../../common/components/ErrorDialog";

export default function TodayTimeTablePage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _timeTabletrips = useSelector((state) => state.timetable.items);
  const [opening, setOpening] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error !!");

  function getCurrentDate(separator = "-") {
   
let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();

today = `${yyyy}${separator}${mm}${separator}${dd}T00:01:00.000Z`
console.log("TODAY ",today)
    return today;
  }
  let newDate = getCurrentDate();
  // console.log("DATE::",newDate);
  useEffectAsync(async () => {
    //console.log("ENTER TO FETCH DATA");
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_NAME}/timetablebytime/${newDate}/SONOTRAK`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        //  console.log("RES OK");
        const data = await response.json();
        dispatch(timetableActions.setItems(data));
        return;
      }
      if (response.status === 401) {
        setErrorMsg("UNAUTHORIZED");
        setOpening(true);
        //console.log("UNAUTHORIZED::", response.status);
        navigate("/login");
      } else {
        setErrorMsg(await response.text());
        setOpening(true);
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const handleOpeningResult = (opening) => {
    setOpening(false);
    if (opening) {
    }
  };

  return (
    <>
      <Header />
      <NavBar size="largeP1" />
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "#021430",
          color: "whitesmoke",
        }}
      >
        <SonotrakTimeTable
          size="largeP1"
          endpoint={`timetablebytime/${newDate}/SONOTRAK`}
          trips={_timeTabletrips}
          action={timetableActions}
          sliceNumber={5}
        />
      </main>
      <footer style={{ position: "sticky", bottom: 0 }}>
        <Footer size="large" />
      </footer>

      <ErrorDialog
        style={{ transform: "none" }}
        open={opening}
        errorMsg={errorMsg}
        onResult={handleOpeningResult}
      />
    </>
  );
}
