import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PreviewIcon from '@mui/icons-material/Preview';

import MailIcon from '@mui/icons-material/Mail';
import { useSelector } from 'react-redux';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import { Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  agenciesActions,
  calendarsActions,
  routesActions,
  sessionActions,
  stopsActions,
  stopTimesActions,
  tripsActions,
  usersActions,
  vehiclesActions,
} from '../store/';
import LogoutIcon from '@mui/icons-material/Logout';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PlaceIcon from '@mui/icons-material/Place';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const _isAdministrator = useSelector(
    (state) => state.session.user.administrator
  );
  const _username = useSelector((state) => state.session.user.email);
  const [menuItems, setMenuItems] = useState(
    _isAdministrator === true
      ? [
          'agencies',
          'trips',
          'stops',
          'stoptimes',
          'routes',
          'vehicles',
          'calendars',
          'users',
        ]
      : ['trips', 'stops', 'routes', 'calendars']
  );
  const [subMenuItems, setSubMenuItems] = useState([
    'timetablep1',
    'sfaxL1p1',
    'sfaxL2',
    'kerkenahl1p1',
    'kerkenahl2',
  ]);

  //console.log("IS ADMIN :: ",_isAdministrator)

  const [subMenuOpen, setSubMenuOpen] = React.useState(false);

  const handleClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem('TOKEN');
    navigate('/login');
    dispatch(sessionActions.updateUser({ user: {} }));
    dispatch(stopsActions.setItems({}));
    dispatch(tripsActions.setItems({}));
    dispatch(routesActions.setItems({}));
    dispatch(agenciesActions.setItems({}));
    dispatch(calendarsActions.setItems({}));
    dispatch(usersActions.setItems({}));
    dispatch(stopTimesActions.setItems({}));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
            TRANSIT APP
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="div"
            style={{ color: 'white', marginLeft: 'auto', marginRight: '5px' }}
          >
            {_username}
          </Typography>
          <IconButton
            variant="outlined"
            size="meduim"
            style={{ backgroundColor: 'white', color: 'red' }}
            onClick={logout}
          >
            <LogoutIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((text, index) => (
            <ListItem
              key={text}
              component={Link}
              to={'/settings/' + text}
              disablePadding
              sx={{ display: 'block', color: 'black' }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {text === 'users' ? (
                    <PeopleOutlineIcon />
                  ) : text === 'agencies' ? (
                    <EmojiTransportationIcon />
                  ) : text === 'trips' ? (
                    <MultipleStopIcon />
                  ) : text === 'stops' ? (
                    <PlaceIcon />
                  ) : text === 'stoptimes' ? (
                    <AccessTimeFilledIcon />
                  ) : text === 'routes' ? (
                    <RouteIcon />
                  ) : text === 'vehicles' ? (
                    <DirectionsBoatIcon />
                  ) : (
                    <CalendarMonthIcon />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              <Divider />
            </ListItem>
          ))}
          <ListItemButton onClick={handleClick}
          >
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText primary="TIMETABLE" />
            {subMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
            <List>
              {subMenuItems.map((text, index) => (
                <ListItem
                  key={text}
                  component={Link}
                  to={'/sonotrak/' + text}
                  disablePadding
                  sx={{ display: 'block', color: 'black' }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: subMenuOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: subMenuOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <VideoLabelIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        text === 'timetablep1'
                          ? 'TimeTable'
                          : text === 'sfaxL1p1'
                          ? 'Sfax LED 1'
                          : text === 'sfaxL2'
                          ? 'Sfax LED 2'
                          : text === 'kerkenahl1p1'
                          ? 'Kerkenah LED 1'
                          : 'Kerkenah LED 2'
                      }
                      sx={{ opacity: subMenuOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                  <Divider />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Outlet />
      </Box>
    </Box>
  );
}
