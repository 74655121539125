import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../common/components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const AgencyPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () =>
    item &&
    item.agency_name &&
    item.agency_id&&
    item.agency_url &&
    item.agency_timezone &&
    item.agency_phone &&
    item.agency_fare_url;

  return (
    <EditItemView
      endpoint="agency"
      item={item}
      setItem={setItem}
      validate={validate}
    //  breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t('sharedRequired')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.agency_name || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_name: event.target.value })
                }
               // label={t('sharedName')}
               label= "Agency Name"
              />
              <TextField
                value={item.agency_id || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_id: event.target.value })
                }
               // label={t('sharedName')}
               label= "Agency ID"
              />
              <TextField
                value={item.agency_url || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_url: event.target.value })
                }
                label= "Agency Url"
                />
              <TextField
                value={item.agency_timezone || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_timezone: event.target.value })
                }
                label= "Agency TimeZone"
                />
              <TextField
                value={item.agency_phone || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_phone: event.target.value })
                }
                label= "Agency Phone"
                />
              <TextField
                value={item.agency_fare_url || ''}
                onChange={(event) =>
                  setItem({ ...item, agency_fare_url: event.target.value })
                }
                label= "Agency Fare Url"

              />
            </AccordionDetails>
          </Accordion>
      
      
        </>
      )}
    </EditItemView>
  );
};

export default AgencyPage;
