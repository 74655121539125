import React, { useState } from "react";
import moment from "moment";

import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditItemView from "../common/components/EditItemView";
import { useTranslation } from "../common/components/LocalizationProvider";
import LinkService from "../common/components/LinkService";


const formatTime = (time) => {
  const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return `${time.locale("en").format("YYYYMMDD")}`;
};

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));
const CalendarPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  const [services, setService] = useState({
    start_date: "",
    end_date: "",
    Monday: 1,
    Tuesday: 1,
    Wednesday: 1,
    Thursday: 1,
    Friday: 1,
    Sunday: 1,
    Saturday: 1,
  });

  const decoded = item && item.data && window.atob(item.data);

  const simple = decoded && decoded.indexOf("//GTFS//") > 0;

  const lines = decoded && decoded.split("\n");


  const validate = () => item && item.start_date && item.name && item.end_date;

  function callBack(attribut, value) {
    //console.log("CallBack Calendar Function ** ATTRIBUT", attribut, value);
    attribut === "Monday"
      ? setItem({ ...item, Monday: value })
      : attribut === "Tuesday"
      ? setItem({ ...item, Tuesday: value })
      : attribut === "Wednesday"
      ? setItem({ ...item, Wednesday: value })
      : attribut === "Thursday"
      ? setItem({ ...item, Thursday: value })
      : attribut === "Friday"
      ? setItem({ ...item, Friday: value })
      : attribut === "Saturday"
      ? setItem({ ...item, Saturday: value })
      : setItem({ ...item, Sunday: value });
  }

  function handleEndDate(e) {
    const time = formatTime(
      moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    );
    //console.log("DATETIME::",time)

    const timeStamp = Math.floor(
      moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL) / 1000
    );

    //console.log("End Time:", time);
    //console.log("End TimeStamp:", timeStamp);

    setItem({ ...item, end_date: time });
  }
  function handleStartDate(e) {
    // console.log(e)
    const time = formatTime(
      moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    );
    const timeStamp = Math.floor(
      moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL) / 1000
    );

    console.log("Start Time:", time);
    //console.log("Start TimeStamp:", timeStamp);

    setItem({ ...item, start_date: time });
  }

  function convertToMomentTime(t) {
    const output = [t.slice(0, 4), "-", t.slice(4, 6), "-", t.slice(6, 8)].join(
      ""
    );

    var date = new Date(output);

    var elapsed = date.getTime() / 1000;

    const newDate = moment.unix(elapsed).format("YYYY-MM-DDTHH:mm:ss");
    return newDate;
  }
  return (
    <EditItemView
      endpoint="calendar"
      item={item}
      setItem={setItem}
      validate={validate}
      // breadcrumbs={['settingsTitle', 'sharedDriver']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{t("sharedRequired")}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                // label={t('sharedName')}
                label="Calendar Name"
              />

      {/*  <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
               
                  components={["DatePicker", "MobileDatePicker"]}
                >
                  <MobileDatePicker
                    label="FROM"
                    value={startDateValue}
                    onChange={(newValue) => setStartDateValue(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>*/}       

              <TextField
                label={t("reportFrom")}
                type="datetime-local"
                value={
                  item.start_date
                    ? convertToMomentTime(item.start_date)
                    : moment().format("YYYY-MM-DDTHH:mm:ss")
                }
                onChange={(e) => handleStartDate(e)}
              />
              <TextField
                label={t("reportTo")}
                type="datetime-local"
                value={
                  item.end_date
                    ? convertToMomentTime(item.end_date)
                    : moment().format("YYYY-MM-DDTHH:mm:ss")
                }
                onChange={(e) => handleEndDate(e)}
              />

              <LinkService
                itemLinked={item}
                baseId={item._id}
                label="Services"
                handleCallBack={callBack}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </EditItemView>
  );
};

export default CalendarPage;
